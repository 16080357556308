const proxy = "";

const WS = "0x0000000000000000000000000000000000000000";//0x0000000000000000000000000000000000000000 recibe los huerfanos por defecto

let INFINITY = "0xD7C4AdDf327e3306E96d41dD648fbaD3EDA2D8fB"; // version 2

let TOKEN = "0x55d398326f99059fF775485246999027B3197955";
let chainId = '0x38';

let TESTNET = false // testnet comand

if (TESTNET) {
    INFINITY = "0xA326D3B5366822d0d13e8Ab8C8CAa114229b8595"; // 

    TOKEN = "0xd5881b890b443be0c609BDFAdE3D8cE886cF9BAc";
    chainId = '0x61';// bnb testnet  || BTTChain "0xC7"//;
}

export default { proxy, WS, INFINITY, TOKEN, chainId };
