import React, { Component } from "react";
const BigNumber = require('bignumber.js');

export default class Oficina extends Component {
  constructor(props) {
    super(props);

    this.state = {
      direccion: "loading Wallet",
      link: "Make an investment to get the referral LINK",
      registered: false,
      balanceRef: 0,
      available: 0,
      totalRef: 0,
      invested: 0,
      paidAt: 0,
      my: 0,
      almacen: 0,
      withdrawn: 0,
      precioSITE: 1,
      valueSITE: 0,
      valueUSDT: 0,
      personasIzquierda: 0,
      puntosIzquierda: 0,
      personasDerecha: 0,
      puntosDerecha: 0,
      bonusBinario: 0,
      puntosEfectivosIzquierda: 0,
      puntosEfectivosDerecha: 0,
      puntosReclamadosIzquierda: 0,
      puntosReclamadosDerecha: 0,
      puntosLostIzquierda: 0,
      puntosLostDerecha: 0,
      directos: 0,
      withdrawableInfinity: 0

    };

    this.Investors = this.Investors.bind(this);
    this.Investors2 = this.Investors2.bind(this);
    this.Investors3 = this.Investors3.bind(this);
    this.Link = this.Link.bind(this);
    this.withdraw = this.withdraw.bind(this);
    this.withdraw2 = this.withdraw2.bind(this);
    this.withdrawTeam = this.withdrawTeam.bind(this);

    this.rateSITE = this.rateSITE.bind(this);
    this.handleChangeSITE = this.handleChangeSITE.bind(this);
    this.handleChangeUSDT = this.handleChangeUSDT.bind(this);

  }

  handleChangeSITE(event) {
    this.setState({ valueSITE: event.target.value });
  }

  handleChangeUSDT(event) {
    this.setState({ valueUSDT: event.target.value });
  }

  async componentDidMount() {

    this.setState({
      currentAccount: this.props.currentAccount,
    });

    setInterval(async () => {
      var verWallet = this.props.currentAccount;

      if (!this.props.wallet.web3.utils.isAddress(verWallet)) {
        verWallet = await this.props.wallet.contractBinary.methods
          .idToAddress(verWallet)
          .call({ from: "0x0000000000000000000000000000000000000000" });
      }
      this.setState({
        currentAccount: verWallet,
      });
      this.Investors2();
      this.Investors3();
      this.Investors();
      this.Link();
    }, 3 * 1000);
  }


  async rateSITE() {

    return 1;
  }

  async Link() {
    const { registered } = this.state;
    if (registered) {
      let loc = document.location.href;
      if (loc.indexOf("?") > 0) {
        loc = loc.split("?")[0];
      }

      if (loc.indexOf("#") > 0) {
        loc = loc.split("#")[0];
      }
      let mydireccion = this.state.currentAccount;
      mydireccion = await this.props.wallet.contractBinary.methods
        .addressToId(this.state.currentAccount)
        .call({ from: this.state.currentAccount });

      mydireccion = loc + "?ref=" + mydireccion;
      var link = mydireccion;
      this.setState({
        link: link,
      });
    } else {
      link = "Make an investment to get the referral LINK";
      this.setState({
        link: link,
      });
    }

    //document.getElementById("linkRefer").value = link;
  }

  async Investors() {
    let usuario = await this.props.wallet.contractBinary.methods
      .investors(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    usuario.withdrawable = await this.props.wallet.contractBinary.methods
      .withdrawable(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    var withdrawableInfinity = await this.props.wallet.contractBinary.methods
      .withdrawable(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    var decimales = await this.props.wallet.contractToken.methods
      .decimals()
      .call({ from: this.state.currentAccount });

    var despositos = await this.props.wallet.contractBinary.methods
      .depositos(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    var valores = despositos[0];
    var valorPlan = 0;

    usuario.withdrawable = new BigNumber(usuario.withdrawable).shiftedBy(-decimales).toNumber();
    usuario.withdrawn = new BigNumber(usuario.withdrawn).shiftedBy(-decimales).toNumber();

    for (let index = 0; index < valores.length; index++) {
      valorPlan += new BigNumber(valores[index]).shiftedBy(-decimales).toNumber();
    }

    //valorPlan = (valorPlan*porcent);//(usuario.invested*porcent);// decimales visuales

    var progresoUsdt =
      ((valorPlan - (valorPlan - (usuario.withdrawn + usuario.withdrawable))) *
        100) /
      valorPlan;

    progresoUsdt = progresoUsdt.toFixed(2);

    var progresoRetiro = ((valorPlan - (valorPlan - usuario.withdrawn)) * 100) / valorPlan;

    progresoRetiro = progresoRetiro.toFixed(2);

    this.setState({
      registered: usuario.registered,
      balanceRef: new BigNumber(usuario.balanceRef).shiftedBy(-18).toNumber(10),
      totalRef: new BigNumber(usuario.totalRef).shiftedBy(-18).toNumber(10),
      invested: new BigNumber(usuario.invested).shiftedBy(-18).dividedBy(30),
      paidAt: new BigNumber(usuario.paidAt).shiftedBy(-18).toNumber(10),
      my: usuario.withdrawable,
      withdrawableInfinity: new BigNumber(withdrawableInfinity).shiftedBy(-18).toNumber(10),
      withdrawn: usuario.withdrawn,
      almacen: new BigNumber(usuario.almacen).shiftedBy(-18).toNumber(10),
      progresoUsdt: progresoUsdt,
      progresoRetiro: progresoRetiro,
      valorPlan: valorPlan,
      directos: usuario.directos
    });
  }

  async Investors2() {
    //var precioSITE = await this.rateSITE();
    /*this.setState({
      precioSITE: precioSITE
    });*/
  }

  async Investors3() {

    var available = await this.props.wallet.contractBinary.methods
      .withdrawable(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    available = available / 10 ** 18;

    var balance = await this.props.wallet.contractToken.methods
      .balanceOf(this.state.currentAccount)
      .call({ from: this.state.currentAccount });

    balance = new BigNumber(balance).shiftedBy(-18).decimalPlaces(2).toString(10);


    var MIN_RETIRO = await this.props.wallet.contractBinary.methods
      .MIN_RETIRO()
      .call({ from: this.state.currentAccount });

    MIN_RETIRO = MIN_RETIRO / 10 ** 18;

    this.setState({
      available: available,
      MIN_RETIRO: MIN_RETIRO,
      balance: "USDT: " + balance,
    });
  }

  async withdraw() {
    var { available } = this.state;

    available = (available * 1).toFixed(6);
    available = parseFloat(available);

    var decimales = await this.props.wallet.contractToken.methods
      .decimals()
      .call({ from: this.state.currentAccount });

    var MIN_RETIRO = await this.props.wallet.contractBinary.methods
      .MIN_RETIRO()
      .call({ from: this.state.currentAccount });
    MIN_RETIRO = MIN_RETIRO / 10 ** decimales;

    if (available >= MIN_RETIRO) {
      await this.props.wallet.contractBinary.methods
        .withdraw()
        .send({ from: this.state.currentAccount });
    } else {
      if (available < MIN_RETIRO) {
        window.alert("The minimum to withdraw are: " + MIN_RETIRO + " USDT");
      }
    }
  }

  async withdraw2() {
    var available = this.state.withdrawableInfinity;

    available = (available * 1).toFixed(6);
    available = parseFloat(available);

    var decimales = await this.props.wallet.contractToken.methods
      .decimals()
      .call({ from: this.state.currentAccount });

    var MIN_RETIRO = await this.props.wallet.contractBinary.methods
      .MIN_RETIRO()
      .call({ from: this.state.currentAccount });
    MIN_RETIRO = MIN_RETIRO / 10 ** decimales;

    if (available >= MIN_RETIRO) {
      await this.props.wallet.contractBinary.methods
        .withdraw2()
        .send({ from: this.state.currentAccount });
    } else {
      if (available < MIN_RETIRO) {
        window.alert("The minimum to withdraw are: " + MIN_RETIRO + " USDT");
      }
    }
  }

  async withdrawTeam() {
    var available = this.state.balanceRef;

    console.log(available)

    available = (available * 1).toFixed(6);
    available = parseFloat(available);

    var decimales = await this.props.wallet.contractToken.methods
      .decimals()
      .call({ from: this.state.currentAccount });

    var MIN_RETIRO = await this.props.wallet.contractBinary.methods
      .MIN_RETIRO()
      .call({ from: this.state.currentAccount });
    MIN_RETIRO = MIN_RETIRO / 10 ** decimales;

    if (available >= MIN_RETIRO) {
      await this.props.wallet.contractBinary.methods
        .withdrawTeam()
        .send({ from: this.state.currentAccount });
    } else {
      if (available < MIN_RETIRO) {
        window.alert("The minimum to withdraw are: " + MIN_RETIRO + " USDT");
      }
    }
  }


  render() {
    var { available, invested } = this.state;

    available = available.toFixed(3);
    available = parseFloat(available);

    invested = parseFloat(invested);

    return (
      <>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-12">
            <div className="card  mb-4">
              <div className="card-body p-3">
                <div className="row">
                  <div className="col-8">
                    <div className="numbers">
                      <p className="text-sm mb-0 text-uppercase font-weight-bold">AREX</p>
                      <h5 className="font-weight-bolder">
                        {invested} ($ {invested * 30})
                      </h5>
                      <p className="mb-0">Received: $ {(this.state.withdrawn + this.state.totalRef - this.state.balanceRef).toFixed(2)} </p>
                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <div className="icon icon-shape bg-gradient-warning shadow-danger text-center rounded-circle"> <i className="fa fa-charging-station text-lg opacity-10" aria-hidden="true"></i> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-12">
            <div className="card  mb-4">
              <div className="card-body p-3">
                <div className="row">
                  <div className="col-8">
                    <div className="numbers">
                      <p className="text-sm mb-0 text-uppercase font-weight-bold">ROI</p>
                      <h5 className="font-weight-bolder">
                        $ {available}
                      </h5>

                      <button className="btn btn-primary btn-sm btn-white bg-gradient-primary mb-0 w-100" onClick={() => this.withdraw()}>Withdraw</button>

                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle"> <i className="fa fa-money-bill-trend-up text-lg opacity-10" aria-hidden="true"></i> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-12">
            <div className="card  mb-4">
              <div className="card-body p-3">
                <div className="row">
                  <div className="col-8">
                    <div className="numbers">
                      <p className="text-sm mb-0 text-uppercase font-weight-bold">TEAM</p>
                      <h5 className="font-weight-bolder">
                        ${this.state.balanceRef.toFixed(1)}
                      </h5>

                      <button className="btn btn-primary btn-sm btn-white bg-gradient-primary mb-0 w-100" onClick={() => this.withdrawTeam()}>Withdraw</button>

                    </div>
                  </div>
                  <div className="col-4 text-end">
                    <div className="icon icon-shape bg-gradient-primary shadow-primary text-center rounded-circle"> <i className="fa fa-people-group text-lg opacity-10" aria-hidden="true"></i> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}
